/**
 * app colors based on style guide
 */
export const COLORS = {
  // style-guide
  BLUE: '#009ECC',
  PINK: '#F10051',
  ORANGE: '#F17700',
  ORANGE_TRS: '#f17700b0',
  GREY: '#747474',
  BLACK_BG: '#1B1C1E',
  MENUITEM_BG: '#1B1C1E',
  WHITE_BG: '#f3f3f3',
}
