import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import '../components/global.css'
import { BeerRender } from '../components/monitorStyle_bw.js'

class Monitor1 extends React.Component {
  render() {
    const beerTaps = get(this, 'props.data.contentfulTappingList.list')
    const availableList = [
      get(this, 'props.data.contentfulTappingList.tap1'),
      get(this, 'props.data.contentfulTappingList.tap2'),
      get(this, 'props.data.contentfulTappingList.tap3'),
      get(this, 'props.data.contentfulTappingList.tap4'),
    ]
    return (
      // <Layout title="Our Menu" location={this.props.location}>
      <BeerRender
        beerTaps={beerTaps}
        startIndex={0}
        availableList={availableList}
      />
      // </Layout>
    )
  }
}

export default Monitor1

export const pageQuery = graphql`
  query BW_TappingQuery1 {
    contentfulTappingList(contentful_id: { eq: "lRdtaBtoERgbv966Yzh1m" }) {
      list {
        brand
        brandFontSize
        name
        detail
        type
        alcoholPercentage
        price
        pricePint
        bangwaPrice
        bangwaPricePint
        specialPrice
        image {
          title
          gatsbyImageData(placeholder: BLURRED, quality: 100)
          fluid {
            src
          }
        }
        country {
          title
          gatsbyImageData(placeholder: BLURRED, quality: 100)
        }
      }
      tap1
      tap2
      tap3
      tap4
    }
  }
`
