import React from 'react'
import styled from 'styled-components'
import { GatsbyImage } from 'gatsby-plugin-image'
import { COLORS } from './utils/styles'

const textBGColor = '#000000b8'
const beerTypeFontColor = '#efbf2e'

const ScWrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  color: white;
  padding: 2px 5px 5px 2px;
`
const ScBeerItem = styled.div`
  position: relative;
  flex-grow: 1;
  height: 100%;
  border-radius: 10px;
  box-shadow: 3px 3px 5px black;
  overflow: hidden;
  background-image: url('${(props) => props.image}');
  background-size: cover;
  background-position: center;

  &:not(:last-child) {
    margin-right: 6px;
  }
`
const ScBrandWrapper = styled.div`
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 10%;
  background-color: ${textBGColor};
  display: flex;
  align-items: center;
  text-transform: uppercase;
  text-align: left;
`
const ScIndex = styled.div`
  font-size: 23px;
  font-weight: bold;
  text-align: center;
  background-color: ${COLORS.ORANGE};
  border-radius: 50%;
  min-width: 35px;
  min-height: 35px;
  line-height: 35px;
  color: black;
  margin: 0 3px;
  box-shadow: 2px 2px 5px black;
`
const ScBrandText = styled.div`
  font-size: 25px;
  line-height: 30px;
  font-weight: bold;
  text-align: center;
  width: 100%;
  margin-right: 45px;
`
const ScCountryImageWrapper = styled.div`
  position: absolute;
  right: 0;
  margin-right: 3px;
  width: 40px;
  box-shadow: 2px 2px 5px black;
`
const ScCountryImage = styled(GatsbyImage)`
  width: 100%;
  height: auto;
`
const ScDescriptionWrapper = styled.div`
  z-index: 1;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 24%;
  background-color: ${textBGColor};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
`
const ScName = styled.div`
  font-size: 25px;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  line-height: 30px;
  margin-top: 2px;
`
const ScDetail = styled.div`
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
`
const ScType = styled.div`
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  color: ${beerTypeFontColor};
`

const ScPriceWrapper = styled.div`
  position: absolute;
  bottom: 10px;
`
const ScPriceFlex = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
const ScPriceItem = styled.div`
  width: 130px;
  text-align: center;
  background-color: ${(props) => (props.color ? props.color : COLORS.ORANGE)};
  border-radius: 10px;
  margin: 0 7px 5px;
  box-shadow: 2px 2px 5px black;
`
const ScMl = styled.div`
  font-size: 20px;
  line-height: 22px;
  font-weight: bold;
  color: black;
`
const ScPrice = styled.div`
  font-size: 42px;
  line-height: 44px;
  font-weight: bold;
  padding-left: 5px;
`
const ScBaht = styled.span`
  font-weight: bold;
  font-size: 18px;
  line-height: 20px;
`
const ScPercent = styled.div`
  background-color: ${textBGColor};
  font-size: 16px;
  font-weight: bold;
  color: white;
  position: absolute;
  right: 5px;
  bottom: 25%;
  padding: 3px;
  border-radius: 5px;
`
const ScSoldout = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: #000000d9;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 60px;
  font-weight: bold;
  color: red;
`

export const BeerRender = ({ beerTaps, startIndex, availableList }) => {
  return (
    <ScWrapper>
      {beerTaps.slice(startIndex, startIndex + 4).map((beer, index) => {
        return (
          <ScBeerItem
            key={`beer-item-${beer.name}`}
            image={beer.image.fluid.src}
          >
            {availableList[index] === false && <ScSoldout>Sold Out!</ScSoldout>}
            <ScPercent>
              ABV {parseFloat(beer.alcoholPercentage).toFixed(1)}%
            </ScPercent>
            <ScBrandWrapper>
              <ScIndex>{index + startIndex + 1}</ScIndex>
              <ScBrandText>{beer.brand}</ScBrandText>
              {beer?.country && (
                <ScCountryImageWrapper>
                  <ScCountryImage
                    image={beer.country.gatsbyImageData}
                    alt={beer.country.title}
                  />
                </ScCountryImageWrapper>
              )}
            </ScBrandWrapper>
            <ScDescriptionWrapper>
              <ScName>{beer.name}</ScName>
              {beer?.detail && <ScDetail>{beer.detail}</ScDetail>}
              <ScType>[ {beer.type} ]</ScType>

              <ScPriceWrapper>
                <ScPriceFlex>
                  {(!!beer.price || !!beer.bangwaPrice) && (
                    <ScPriceItem>
                      <ScMl>330 ml.</ScMl>
                      <ScPrice>
                        {beer.bangwaPrice ? beer.bangwaPrice : beer.price}
                        <ScBaht>฿</ScBaht>
                      </ScPrice>
                    </ScPriceItem>
                  )}

                  {(!!beer.pricePint || !!beer.bangwaPricePint) && (
                    <ScPriceItem color={COLORS.PINK}>
                      <ScMl>500 ml.</ScMl>
                      <ScPrice>
                        {beer.bangwaPricePint
                          ? beer.bangwaPricePint
                          : beer.pricePint}
                        <ScBaht>฿</ScBaht>
                      </ScPrice>
                    </ScPriceItem>
                  )}
                </ScPriceFlex>
              </ScPriceWrapper>
            </ScDescriptionWrapper>
          </ScBeerItem>
        )
      })}
    </ScWrapper>
  )
}
